import '../candidatesSubmit/candidatesSubmit.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import aiLogo from '../../assets/img/logo_ai_films.png';

export default function TermsConditions() {
	const navigate = useNavigate();
	return (
		<div className="submission">
			<div className="top">
				<div className="wrapper">
					<a href="/">
						<img className="logo" src={aiLogo} alt="" />
					</a>
					<a role="button" onClick={() => navigate('/terms-conditions')} className="terms">
						Terms and Conditions
					</a>
				</div>
			</div>
			<div className="container">
				<h1>AI Films</h1>
				<p className="aifilms">The Future of Story Telling</p>
				<h2>Terms and Conditions for Candidate Registration</h2>
				<div>
					<p style={{ fontSize: '15px' }}>
						Please read the following terms and conditions (&quot;Terms&quot;) carefully before
						registering as a candidate on the streaming platform (&quot;Platform&quot;). These Terms
						govern your access to and use of the Platform as a candidate, and constitute a legally
						binding agreement between you and the Platform.
					</p>
					<br />
					<ol>
						<li>
							<b>Acceptance of Terms</b> By registering as a candidate on the Platform, you
							acknowledge and agree to be bound by these Terms. If you do not agree with any of the
							provisions of these Terms, you must not register as a candidate on the Platform.
						</li>
						<br />
						<li>
							<b>Eligibility</b> To register as a candidate on the Platform, you must be at least 18
							years of age or have obtained legal consent from a parent or guardian if you are under
							the age of 18. You must also have the legal capacity to enter into a contract.
						</li>
						<br />
						<li>
							<b>Registration Information</b> To complete the registration process on the Platform,
							you must provide accurate, current, and complete information about yourself, including
							your name, email address, and a link where your work can be viewed (e.g., Instagram
							social media account).
						</li>
						<br />
						<li>
							<b>Email Communication</b> By registering as a candidate on the Platform, you consent
							to receive emails from the Platform related to your registration and any relevant
							updates. These emails may include information about your account, notifications,
							promotional offers, and other communication related to your candidacy. You may opt-out
							of receiving promotional emails at any time by following the unsubscribe instructions
							provided in the email.
						</li>
						<br />
						<li>
							<b>Use of Provided Link</b> By registering as a candidate on the Platform, you grant
							the Platform the non-exclusive, worldwide, royalty-free right to use, display, and
							promote the provided link where your work can be viewed. This includes, but is not
							limited to, featuring your work on the Platform, showcasing it to potential viewers,
							and sharing it on social media platforms or other promotional channels for the purpose
							of promoting the Platform and its candidates. You retain all ownership rights to your
							work.
						</li>
						<br />
						<li>
							<b>Prohibited Conduct</b> As a candidate on the Platform, you agree not to engage in
							any of the following activities:
						</li>
						<br />
						<ul>
							<li>Violating any applicable laws, regulations, or third-party rights.</li>
							<li>
								Impersonating any person or entity or falsely representing your affiliation with any
								person or entity.
							</li>
							<li>
								Uploading, posting, transmitting, or otherwise making available any content that is
								harmful, defamatory, obscene, or infringing upon any intellectual property rights.
							</li>
							<li>
								Attempting to gain unauthorised access to the Platform, its servers, or any other
								systems or networks connected to the Platform.
							</li>
							<li>
								Engaging in any activity that disrupts, interferes with, or imposes an unreasonable
								burden on the Platform&lsquo;s infrastructure or its proper functioning.
							</li>
						</ul>
						<br />
						<li>
							<b>Intellectual Property</b>
							The Platform respects the intellectual property rights of others and expects you to do
							the same. By registering as a candidate, you affirm that you have the necessary rights
							or permissions to use and display the content provided in the link where your work can
							be viewed.
						</li>
						<br />
						<li>
							<b>Disclaimer of Warranties</b> The Platform provides the candidate registration
							service on an &ldquo;as is&ldquo; and &ldquo;as available&ldquo; basis. To the fullest
							extent permitted by applicable law, the Platform disclaims all warranties, whether
							express or implied, including but not limited to the warranties of merchantability,
							fitness for a particular purpose, and non-infringement.
						</li>
						<br />
						<li>
							<b>Limitation of Liability</b> To the fullest extent permitted by applicable law, the
							Platform shall not be liable for any indirect, incidental, special, consequential, or
							punitive damages, including but not limited to loss of profits, data, or goodwill,
							arising from your use of the Platform or your inability to use the Platform.
						</li>
						<br />
						<li>
							<b> Modification of Terms</b> The Platform reserves the right to modify or amend these
							Terms at any time. Any changes to the Terms will be effective immediately upon posting
							the updated version on the Platform. It is your responsibility to review the Terms
							periodically for any updates. By continuing to use the Platform after the posting of
							changes, you accept and agree to be bound by the modified Terms.
						</li>
						<br />
						<li>
							<b>Termination</b>
							The Platform reserves the right to suspend or terminate your candidacy and access to
							the Platform at any time, without prior notice or liability, for any reason, including
							but not limited to a violation of these Terms.
						</li>
						<br />
						<li>
							<b>Governing Law and Jurisdiction</b> These Terms shall be governed by and construed
							in accordance with the laws of the jurisdiction where the Platform operates. Any
							disputes arising out of or relating to these Terms or your use of the Platform shall
							be subject to the exclusive jurisdiction of the competent courts in that jurisdiction.
						</li>
						<br />
						<li>
							<b>Severability</b> If any provision of these Terms is found to be unenforceable or
							invalid, that provision shall be limited or eliminated to the minimum extent
							necessary, and the remaining provisions of these Terms shall remain in full force and
							effect.
						</li>
						<br />
						<li>
							<b>Entire Agreement</b> These Terms constitute the entire agreement between you and
							the Platform regarding your registration as a candidate on the Platform, superseding
							any prior agreements or understandings, whether oral or written.
						</li>
						<br />
						<br />
					</ol>
				</div>
				<div>
					<p>
						By accepting these Terms, you acknowledge that you have read, understood, and agree to
						be bound by all of the provisions contained herein. If you do not agree with any part of
						these Terms, you should not proceed with the registration process.
					</p>
				</div>
			</div>
		</div>
	);
}
